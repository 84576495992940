import React from "react"
// Components
import Layout from "../components/layout/Layout"
import Header from "../components/layout/Header"
import ThreeFeaturesAssetOwners from "../components/carousels/ThreeFeaturesAssetOwners"
import FeaturedSection from "../components/layout/FeaturedSection"
import ParallaxContainer from "../components/parallax/ParallaxContainer"
import FadingTitleC from "../components/general/FadingTitleC"
import FloatingContainer from '../components/floats/FloatingContainer'
import FloatingShape from '../components/floats/FloatingShape'
import Card from '../components/general/Card'
import Button from '../components/general/Button'

// Images
import CrownEstate from '../images/other/The_crown_estate_blue.png'
import OfficeImage from '../images/other/B0A8237.jpg'

const AssetOwnersPage = (props) => {

    const { location } = props;


    return (
        <Layout
            gutters={false}
            container={false}
            activePage={"/asset-owners"}
            location={location}
        >
            <Header
                title="For Asset Owners | Attract Your Perfect Occupiers | Kontor" 
                description="Helping asset owners create the perfect office space that exceed customer exspecations by using our expertise & insight in to London's most exciting offices"    
            />

            {/* Landing Section  */}
            <FadingTitleC
                title={<>We help London's progressive asset-owners to <span className="border-b-2 border-secondary font-bold">attract their perfect occupiers</span></>}
                boxText={<>At Kontor, our mission is to be the trusted partner helping amazing companies and amazing offices find each other. We have an unrivalled insight into the requirements of London's most exciting  Flexible, Managed, and Leased workspace occupiers, which we utilise to assist asset owners in creating spaces that cater to their customers' needs. With our expertise and guidance, you can create a space where your customers will thrive. Offices are personal, so are we.
                </>}
                path={location.search || ""}
                loadAnimation={false}
                textUnderCta="Answer some quick questions, we’ll do the rest."
            />

            {/*  Demo Space 2 */}
            <div className="mt-4 mb-4 md:mt-4 text-center" style={{paddingTop: '4rem'}}>
                <Button linkTo="/available-properties" type="secondary" className="mx-4">
                    View Featured Offices
                </Button>
            </div>

            {/* Featured Section */}
            <p className="flex justify-center md:pb-3 sm:pb-16" style={{ paddingTop: '1rem', color: '#FECC02'}}>Trusted By:</p>
            <FeaturedSection className="max-w-6xl mx-auto md:px-6 mb-24 sm:pb-16" page="asset owners"/>

            {/*  Features Bar */}
            <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
                className="mb-20 max-w-6xl mx-auto px-6 relative md:-mt-16"
                style={{zIndex: '10', paddingTop: "5rem"}}
            >
                <ThreeFeaturesAssetOwners
                    features={[
                        {
                            title: "Accessing 100% of demand",
                            subTitle: <>Now that Customers have replaced Occupiers we have not only seen a seismic shift in what is being delivered but also who it is being delivered to, and how. At Kontor, we are driving change in the way that our asset owner’s workspace hits the market <br/> – accessing beyond the agent community with innovative tenant-led marketing techniques delivered directly to your audience giving your building the exposure it deserves.</>
                        },
                        {
                            title: "Delivering the spectrum of space",
                            subTitle: <>A decade ago, customers had to pick between an office lease for 5+ years or a few makeshift desks in a glass box, alongside hundreds of other companies. Since then, the landscape has changed beyond recognition, the lines have blurred and we now view workspace across a spectrum. Leased, fully managed, or flexible, our approach ensures our asset owners' buildings are future-proofed and engage with all of their customers.</>
                        },
                        {
                            title: "Insights backed by data",
                            subTitle: <>For years, Kontor has been the market leader in tenant acquisitions. We bring the end consumer closer to you from the beginning, giving you first-hand tenant insights. Our extensive data covers all sub-markets, allowing you to better understand your customer, get ahead of trends, and provide actionable insights  for the next generation of occupiers, powered by data. Powered by Kontor.</>
                        }
                    ]}
                />
            </div>

            {/* Features (2) */}
            {/* Rating Half Circle */}
            {/* <FloatingContainer
                className="w-1/2 left-1/4 top-48"
                floatSpeed="custom"
                speed={10000}
                floatType="largeWobbleDrift"
                height="300px"
            >
                <FloatingShape height={110} width={110} shape="circle" />
            </FloatingContainer> */}

            {/* Demo Space 1  */}
            <div
                className="mx-auto text-center mt-24 relative" style={{ maxHeight: '500px' }}>
                {/* <FloatingContainer
                    className="lg:w-1/2 w-full mx-auto lg:left-1/4 -top-32 lg:top-0 "
                    floatSpeed="custom"
                    speed={5000}
                    floatType="spin"
                    height="200px"
                >
                    <FloatingShape
                        height={160}
                        width={160}
                        shape={"halfMoon"}
                    />
                </FloatingContainer> */}
                <ParallaxContainer
                    weighting={1.3}
                    reverse={true}
                    early={100}
                    className="justify-center"
                >
                    <Card
                        style={{ height: '300px', maxWidth: '400px' }}
                        type="secondary"
                        className="flex flex-col flex-shrink-0 w-95p md:w-full md:-mr-12 md:ml-0 top-32 md:top-48 absolute md:relative overflow-hidden transition-quick -bottom-48 shadow-xl z-10"
                    >
                     <div className="flex flex-col items-center justify-between h-full">
                            <p className="text-xl text-center leading-6 my-auto pb-2 md:pb-8">{`“They are equally happy giving great advice on future schemes and rolling up their sleeves to get stuck into some proper agency. Importantly they are really good, honest and genuine people who act with integrity and are always enjoyable to work with.”`}</p>
                            {CrownEstate && (
                                <img className="w-24" src={CrownEstate} alt='The Crown Estate' />
                            )}
                        </div>
                    </Card>
                    <img className="inline-block md:mt-40 transition-quick self-end max-w-90p md:max-w-sm" src={OfficeImage} alt="Office Image" />
                    
                </ParallaxContainer>

                {/* <FloatingContainer
                    className="lg:w-1/2 w-full mx-auto lg:left-1/2 -top-32 lg:top-0 "
                    floatSpeed="custom"
                    speed={5000}
                    floatType="wobbleDrift"
                    height="400px"
                >
                    <FloatingShape
                        height={180}
                        width={180}
                        shape={"polygon"}
                    />
                </FloatingContainer> */}
            </div>


            {/* Regulated Section */}
            <div
                // Fade up
                data-aos="fade-up" data-aos-duration="1000"
                className="flex flex-col md:flex-row max-w-5xl mx-auto my-24  px-0">

                <div className="w-full lg:w-1/2 relative self-center">

{/* 
                    <FloatingContainer
                        className="w-full lg:-top-32 "
                        floatSpeed="custom"
                        speed={5000}
                        floatType="drift"
                        height="600px"
                        rotate={false}
                    >
                        <FloatingShape
                            height={100}
                            width={100}
                            shape={"circle"}
                        />
                    </FloatingContainer> */}
                </div>

                </div>


            <div className="mt-24 md:mt-12 text-center mb-64 md:mb-24 z-1 relative">
                {/* <BotButton id="h-lb-getgoing" type="secondary">Get going in under 60 seconds</BotButton> */}
            </div> 
        </Layout >
    )
}

export default AssetOwnersPage
